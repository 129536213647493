/*#region Navbar */

header {
  z-index: 999;
  position: relative;
  width: 100%;
  height: 5rem;
}

.header-container {
  width: 100%;
  position: fixed;
}

.primary-header {
  padding: 0;
  padding-bottom: var(--size-400);
}

.upper-nav {
  color: var(--clr-neutral-white);
  background-color: var(--clr-accent-700);
  display: grid;
  justify-content: center;
  padding: 0.3rem;
}

.upper-nav p {
  font-size: var(--fs-400);
  max-width: 40ch;
}

.nav {
  --max-width: 1700px;
  --container-padding: 2rem;

  padding-top: var(--size-400);
  width: min(var(--max-width), 100% - (var(--container-padding) * 2));
  margin-inline: auto;
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: center;
}

.nav-logo {
  margin: auto 0;
  max-width: 12rem;
}

.nav-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav-mobile img {
  width: 3rem;
  cursor: pointer;
}

@media (min-width: 30em) {
  .nav-mobile {
    display: none !important;
  }

  .nav-extended-links {
    display: block;
  }
}

@media (max-width: 30em) {
  .nav-extended-links {
    display: none !important;
  }
}

.nav-mobile-menu {
  position: absolute;
  width: 100%;
  z-index: 102;
}

.nav-mobile-links {
  width: 100%;
  display: grid;
  padding: 1.5rem 2.5rem 1.5rem 2.5rem;
  gap: 0.6rem;
  text-align: left;
  justify-content: flex-start;
  grid-template-columns: 1fr;
}

.nav-mobile-links .horizontal-line {
  margin-bottom: 0.2rem;
}

.nav-mobile-links .btn {
  box-shadow: none;
  width: 100%;
  padding: 1em 2em;
  margin-top: 1rem;
}

.nav-link {
  font-weight: var(--fw-bold);
  cursor: pointer;
  text-decoration: none;
  color: var(--clr-neutral-white);
  font-size: var(--fs-600);
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  padding: 0.5rem 0 0.5rem 0;
}

.nav-link {
  text-align: left;
}

.nav-mobile {
  display: grid;
  gap: 0.4rem;
  cursor: pointer;
  position: relative;
  user-select: none;
}

.nav-burger-icon {
  background-color: var(--clr-neutral-white);
  height: 0.2rem;
  width: 2.5rem;
  border-radius: 1rem;
  position: relative;
}

.nav-burger-icon-upper,
.nav-burger-icon-lower {
  margin: auto;
}

.nav-burger-icon-upper[data-type="toX"] {
  animation: rotateUpperToX 0.3s linear forwards;
}

.nav-burger-icon-upper[data-type="reverse"] {
  animation: reverseUpperFromX 0.3s linear forwards;
}

.nav-burger-icon-center[data-type="toX"] {
  animation: rotateCenterToX 0.1s linear forwards;
}

.nav-burger-icon-center[data-type="reverse"] {
  animation: reverseCenterFromX 0.2s linear forwards;
}

.nav-burger-icon-lower[data-type="reverse"] {
  animation: reverseLowerFromX 0.3s linear forwards;
}

.nav-burger-icon-lower[data-type="toX"] {
  animation: rotateLowerToX 0.3s linear forwards;
}

@keyframes rotateUpperToX {
  0% {
    top: 0rem;
  }

  100% {
    top: 0.5rem;
    transform: rotate(-45deg);
  }
}

@keyframes reverseUpperFromX {
  0% {
    top: 0.5rem;
    transform: rotate(-45deg);
  }

  100% {
    top: 0rem;
    transform: rotate(0deg);
  }
}

@keyframes rotateCenterToX {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes reverseCenterFromX {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes rotateLowerToX {
  0% {
    bottom: 0rem;
  }
  100% {
    bottom: 0.7rem;
    transform: rotate(45deg);
  }
}

@keyframes reverseLowerFromX {
  0% {
    bottom: 0.7rem;
    transform: rotate(45deg);
  }

  100% {
    bottom: 0rem;
    transform: rotate(0deg);
  }
}

/*#endregion*/

/*#region Welcome */

#home img {
  transform: scale(1.2);
  padding-top: 2rem;
}

.welcome-wrapper {
  max-width: 38ch;
  margin: auto;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  justify-content: center;
  margin: auto;
  hyphens: auto;
}

.welcome-header {
  text-align: center !important;
  font-size: var(--fs-600);
}

.welcome-paragraph {
  font-size: var(--fs-500);
  line-height: 2;
  text-align: justify;
  max-width: 100%;
}

.welcome-team-list {
  padding-left: 2rem;
  margin-top: -0.8rem;
}

.welcome-team-list-item {
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 0.4rem;
}

.welcome-team-list-job {
  font-size: var(--fs-300);
  margin-top: -0.4rem;
  font-weight: bold;
}

.welcome-team-list-name {
  font-size: var(--fs-550);
}

/*#endregion Welcome */

/*#region News */

.news-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.news-list-item {
  display: grid;
  background-color: var(--clr-neutral-white);
  margin: auto;
  grid-template-columns: 1fr;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 0.3rem;
}

.news-list-item-img {
  height: 100%;
  width: 100%;
  border-radius: 0.3rem 0.3rem 0 0;
}

.news-list-item-content {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  justify-content: center;
  padding: 2rem 1.5rem;
}

.news-list-item-header {
  font-size: var(--fs-625);
  color: var(--clr-accent-700);
  font-weight: var(--fw-bold);
}

.news-list-item-date {
  font-size: var(--fs-500);
  color: var(--clr-shadow-text);
  font-weight: var(--fw-bold);
}

.news-list-item-text {
  font-size: var(--fs-400);
  max-width: 36ch;
  text-align: justify;
}

.news-list-item-link {
  font-size: var(--fs-500);
  color: var(--clr-accent-700);
  font-weight: var(--fw-bold);
  margin-top: 1rem;
  margin-left: auto;
  user-select: none;
  cursor: pointer;
  text-decoration: underline;
}

.news-list-item-link:hover {
  opacity: 0.7;
}

.news-list-item-list {
  padding-left: 1rem;
  display: grid;
  gap: 0.5rem;
  line-height: 2;
}

.news-list-item-list-item {
  font-size: var(--fs-400);
  max-width: 100%;
}

/*#endregion News */

/*#region Service */

#leistungen {
  color: var(--clr-neutral-white);
}

#leistungen .btn {
  width: 18rem;
  margin: auto;
  box-shadow: none;
}

.service-wrapper {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.service-heading {
  font-size: var(--fs-650);
  font-weight: var(--fw-bold);
}

.service-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.service-item {
  display: grid;
  grid-template-columns: 12% 88%;
  gap: 0;
}

.service-item-img-wrapper {
  background-color: var(--clr-gray-text);
  height: 2rem;
  width: 2rem;
  border-radius: 10rem;
  display: flex;
  margin: 0;
}

.service-item-content-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.2rem;
}

.service-item-header {
  font-size: var(--fs-625);
}

.service-item-preview {
  font-size: var(--fs-400);
  font-weight: var(--fw-regular);
  color: var(--clr-gray-text);
}

.service-item-nav {
  margin-top: 0.4rem;
}

.service-item-link {
  font-size: var(--fs-400);
  color: var(--clr-accent-600);
  text-decoration: none;
  cursor: pointer;
}

/*#endregion Service */

/*#region Location */

#location iframe {
  width: 100%;
  height: 20rem;
  border: 0;
  border-radius: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

/*#endregion Location */

/*#region Footer */

.primary-footer-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2em;
  height: 30rem;
  padding: 0rem 1rem 0 3rem;
  padding-top: 0;
}

@media (max-width: 50em) {
  .primary-footer-wrapper > * {
    margin-inline: auto;
  }
}

.primary-footer-officeTimes {
  width: 90%;
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
  padding-top: 2rem;
}

.primary-footer-officeTimes-header {
  color: var(--clr-neutral-white);
  font-size: var(--fs-600);
  margin-bottom: 1rem;
}

.primary-footer-officeTimes-item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: var(--clr-neutral-white);
  align-items: flex-start;
  justify-content: flex-start;
}

.primary-footer-officeTimes-item p {
  font-size: var(--fs-400);
}

.primary-footer-links {
  display: flex;
  justify-content: flex-start;
  width: 90%;
  margin: auto;
  padding-bottom: 2rem;
  margin-top: 1rem;
  color: var(--clr-neutral-white);
}

.primary-footer-nav {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: flex-start;
  width: 90%;
  margin: 0 auto;
  margin-top: 1rem;
}

.primary-footer-nav a {
  color: var(--clr-neutral-white);
}

.footer-nav-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  margin-right: auto;
  margin-left: 0;
}

.footer-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  text-align: left;
}

.footer-logo-links img {
  height: var(--size-700);
}

/*#endregion Footer*/

/*#region ImgSlider */

.card-slider {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  margin: auto;
  height: 18rem;
}

.card-link-list {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 2rem;
}

.card {
  overflow: hidden;
  height: 100%;
}

.card img {
  transform: scale(1.2);
  padding-top: 1.4rem;
}

.card-slider-pagination-wrapper {
  position: absolute;
  bottom: 0;
  z-index: 98;
  left: 1;
  right: 0;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.card-slider-pagination {
  display: flex;
  gap: 0.6rem;
  justify-content: center;
}

.card-slider-pagination-chevron {
  height: 1.6rem;
  width: 1.6rem;
  cursor: pointer;
}

.card-slider-pagination-item {
  width: 1rem;
  height: 1rem;
  border-radius: 10rem;
  background-color: var(--clr-neutral-white);
  cursor: pointer;
  box-shadow: 0 1.25em 1em -1em var(--clr-neutral-black-bs);
}

.card-slider-pagination-item-active {
  background-color: var(--clr-accent-700);
}

/*#endregion ImgSlider */

/*#region Kurse */

.kurs-top-img {
  width: 100%;
}

.kurs-top-img img {
  width: 100%;
}

.kurs-container {
  position: relative;
  z-index: 100;
  background-color: var(--clr-neutral-white);
  margin-top: 3rem !important;
}

.kurs-header {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.2rem;
  padding: 1.5rem 0 2.5rem 0;
}

.kurs-header-heading {
  font-size: var(--fs-650);
  font-weight: var(--fw-bold);
}

.kurs-header-sub-heading {
  font-size: var(--fs-400);
  text-align: justify;
  line-height: 2;
}

.kurs-header .btn {
  margin-top: 1rem;
}

.kurs-container .horizontal-line {
  border-bottom: 1px solid var(--clr-gray-text);
}

.kurs-group-container {
  padding: 2rem 0 4rem 0;
}

.kurs-group-header {
  font-size: var(--fs-650);
  font-weight: var(--fw-bold);
  margin-bottom: 1.5rem;
}

.kurs-group {
  display: grid;
  grid-template-columns: 3fr;
  gap: 2rem;
}

.kurs-group-item {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
  cursor: pointer;
  text-decoration: none;
  color: var(--clr-neutral-black);
}

.kurs-group-item img {
  border-radius: 1rem;
}

.kurs-group-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 1rem;
}

.kurs-group-item-header {
  font-size: var(--fs-600);
  font-weight: var(--fw-bold);
  justify-self: flex-start;
}

.kurs-group-item-link {
  font-size: var(--fs-400);
  color: var(--clr-accent-700);
  margin-left: auto;
  text-decoration: underline;
}

.kurs-reminder-container {
  padding: 2.5rem 1rem 3rem 1rem;
  color: var(--clr-neutral-white);
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.kurs-reminder-header {
  font-size: var(--fs-625);
  font-weight: var(--fw-bold);
  hyphens: auto;
}

.kurs-reminder-text {
  font-size: var(--fs-500);
  max-width: 100%;
  text-align: justify;
}

.kurs-reminder-container .even-areas {
  margin-top: 1rem;
  gap: 2rem;
}

/*#endregion Kurse */

/*#region Kursplan */

.kurs-plan-container {
  padding: 4rem 1rem 6rem 1rem;
  background-color: var(--clr-neutral-white);
}

.kurs-plan-header {
  font-size: var(--fs-650);
  font-weight: var(--fw-bold);
}

.kurs-plan-control-week-switch {
  display: flex;
  gap: 0.2rem;
  align-items: center;
  justify-content: flex-end;
  padding-top: 1.5rem;
}

.kurs-plan-control-week-switch[data-type="nextWeek"] {
  justify-content: flex-start;
}

.kurs-plan-control-switch-header {
  font-size: var(--fs-400);
  text-decoration: underline;
  cursor: pointer;
}

.kurs-plan-control-switch-icon {
  height: 1.5rem;
  cursor: pointer;
}

.kurs-plan-week-control {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: center;
  align-items: center;
  padding: 1rem 0 1rem 0;
  margin-bottom: 1rem;
}

.kurs-plan-week-control-weekday {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  text-align: center;
  gap: 0.2rem;
  cursor: pointer;
}

.kurs-plan-week-control-weekday-dayName {
  font-size: var(--fs-400);
}

.kurs-plan-week-control-weekday-date {
  font-size: var(--fs-500);
  background-color: var(--clr-gray-bg);
  height: 2rem;
  width: 2rem;
  border-radius: 0.4rem;
  text-align: center;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.kurs-plan-week-control-weekday-date[data-type="active"] {
  background-color: var(--clr-neutral-black);
  color: var(--clr-neutral-white);
}

.kurs-plan-week-content {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  padding: 1rem 0;
}

.kurs-plan-week-content-list {
  min-height: 20rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.6rem;
}

.kurs-plan-week-current-date {
  font-size: var(--fs-400);
  font-weight: var(--fw-bold);
  text-align: center;
}

.kurs-plan-week-content-wrapper {
  display: grid;
  grid-template-columns: 0.6fr 0.4fr;
  padding: 0.8rem 0rem;
  border-radius: 0.3rem;
  align-items: center;
}

.kurs-plan-week-content-wrapper[data-type="1"],
.kurs-plan-color-item-dot[data-type="1"],
.kurs-plan-weekview-table-content-item[data-type="1"] {
  background-color: var(--clr-light-blue);
}

.kurs-plan-week-content-wrapper[data-type="2"],
.kurs-plan-color-item-dot[data-type="2"],
.kurs-plan-weekview-table-content-item[data-type="2"] {
  background-color: var(--clr-accent-700);
}

.kurs-plan-week-content-title {
  padding-left: 1rem;
  font-size: var(--fs-600);
}

.kurs-plan-week-content-time {
  padding-right: 1rem;
  display: flex;
  justify-content: flex-end;
  font-size: var(--fs-500);
}

.kurs-plan-week-content-wrapper[data-type="error"] {
  display: block;
  text-align: center;
}

.kurs-plan-week-content-error {
  font-size: var(--fs-625);
  font-weight: var(--fw-bold);
}

.kurs-plan-weekview-switch {
  display: grid;
  grid-template-columns: 15% 70% 15%;
  align-items: center;
  justify-items: center;
  width: 16rem;
  height: 3rem;
  border: 1px solid var(--clr-neutral-black-bs);
  border-radius: 1rem;
  user-select: none;
  margin-left: auto;
  margin-bottom: 2rem;
  background-color: var(--clr-gray-bg);
}

.kurs-plan-weekview-switch-date-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  margin: auto;
  text-align: center;
  border-left: 1px solid var(--clr-neutral-black-bs);
  border-right: 1px solid var(--clr-neutral-black-bs);
  height: 100%;
  width: 100%;
}

.kurs-plan-weekview-switch-icon {
  margin: auto;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  height: 100%;
}

.kurs-plan-weekview-switch-icon img {
  height: 1.2rem;
}

.kurs-plan-weekview {
  margin-bottom: 2rem;
}

.kurs-plan-weekview-table {
  display: grid;
  grid-template-columns: 1fr;
}

.kurs-plan-weekview-table-header {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid var(--clr-neutral-black-bs);
  user-select: none;
  border-radius: 0.5rem 0.5rem 0 0;
}

.kurs-plan-weekview-table-header-text {
  font-size: var(--fs-600);
  font-weight: var(--fw-bold);
  padding: 1rem 0;
  margin: auto;
  height: 100%;
  width: 100%;
  border-right: 1px solid var(--clr-neutral-black-bs);
}

.kurs-plan-weekview-table-header-text:last-of-type {
  border-right: none;
}

.kurs-plan-weekview-table-content {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  border-bottom: 1px solid var(--clr-neutral-black-bs);
  border-left: 1px solid var(--clr-neutral-black-bs);
  border-right: 1px solid var(--clr-neutral-black-bs);
  border-radius: 0 0 0.5rem 0.5rem;
}

.kurs-plan-weekview-table-column {
  border-right: 1px solid var(--clr-neutral-black-bs);
  padding-top: 1rem;
}

.kurs-plan-weekview-table-column:last-of-type {
  border: none;
}

.kurs-plan-weekview-table-content-item {
  width: 94%;
  margin: auto;
  margin-bottom: 1rem;
  border-radius: 1rem;
  height: 5rem;
  display: grid;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  padding: 0.5rem;
}

.kurs-plan-weekview-table-content-item-title {
  font-weight: var(--fw-bold);
  text-align: center;
}

.kurs-plan-weekview-table-content-item-time {
  text-align: center;
}

.kurs-plan-index-controller {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  width: 10rem;
  border: 1px solid var(--clr-neutral-black-bs);
  border-radius: 0.5rem;
  align-items: center;
  justify-items: center;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.kurs-plan-index-controller-item {
  width: 100%;
  height: 100%;
  padding: 0.5rem 0.5rem;
  color: var(--clr-neutral-black);
  background-color: var(--clr-gray-bg);
  font-weight: var(--fw-bold);
  font-size: var(--fs-600);
  cursor: pointer;
}

.kurs-plan-index-controller-item:first-of-type {
  border-radius: 0.3rem 0 0 0.3rem;
}

.kurs-plan-index-controller-item:last-of-type {
  border-radius: 0 0.3em 0.3rem 0;
}

.kurs-plan-index-controller-item[data-type="active"] {
  color: var(--clr-neutral-white);
  background-color: var(--clr-neutral-black);
}

.kurs-plan-color-scheme {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin-top: 1rem;
}

.kurs-plan-color-item {
  display: grid;
  grid-template-columns: 0.5fr 11.5fr;
  gap: 1rem;
  align-items: flex-start;
  text-align: left;
  hyphens: auto;
}

.kurs-plan-color-item-dot {
  height: 1rem;
  width: 1rem;
  border-radius: 10rem;
}

.kurs-plan-color-item-title {
  font-size: var(--fs-400);
  display: flex;
  align-items: center;
}

/*#endregion Kursplan */

/*#region Mitgliedschaft */

.subscription-container {
  background-color: var(--clr-neutral-white);
  padding: 3rem 1rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

.subscription-info {
  font-size: var(--fs-400);
  margin-top: 1.5rem;
  max-width: 100%;
  text-align: justify;
  hyphens: auto;
  padding: 0 0.5em;
}

.subscription-header {
  font-size: var(--fs-700);
  font-weight: var(--fw-bold);
}

.subscription-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin: 2rem 0;
}

.subscription-item {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  border: 2px solid var(--clr-gray-bg);
  border-radius: 0.5rem;
  padding: 2rem 1.2rem;
  box-shadow: 0 0.5em 1em -0.5em var(--clr-neutral-black-bs);
}

.subscription-item-heading {
  display: grid;
  grid-template-columns: 3fr 1fr;
}

.subscription-item-title {
  font-size: var(--fs-650);
  font-weight: var(--fw-bold);
}

.subscription-item-price {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: flex-end;
  text-align: right;
}

.subscription-item-price-tag {
  font-size: var(--fs-625);
  font-weight: var(--fw-bold);
}

.subscription-item-price-period {
  font-size: var(--fs-600);
  font-weight: var(--fw-bold);
}

.subscription-item-info {
  font-size: var(--fs-500);
}

.subscription-item-control {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.subscription-item-purchase-btn {
  background-color: var(--clr-accent-700);
  padding: 0.4rem 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--clr-neutral-white);
  font-size: var(--fs-650);
  border-radius: 0.5rem;
  cursor: pointer;
}

.subscription-item-purchase-btn:hover {
  opacity: 0.7;
}

.subscription-item-details-btn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 12rem;
  align-items: center;
  gap: 1rem;
  padding: 0.4rem 0 1rem 0;
  cursor: pointer;
}

.subscription-item-details-btn-text {
  font-size: var(--fs-625);
  font-weight: var(--fw-bold);
}

.subscription-item-details-btn-icon {
  width: 2.5rem;
}

.subscription-item-dropdown {
  display: none;
}

.subscription-item-dropdown[data-type="dropped"] {
  display: block;
}

.subscription-item-dropdown .horizontal-line {
  border: 1px solid var(--clr-gray-text);
  width: 90%;
  margin: auto;
}

.subscription-item-detail-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  padding-top: 1.8rem;
}

.subscription-item-detail-item {
  display: grid;
  grid-template-columns: 1fr 11fr;
  gap: 1rem;
  margin: auto;
}

.subscription-item-detail-item-icon {
  width: 1.4rem;
}

.subscription-item-detail-item-text {
  font-size: var(--fs-400);
  line-height: 2;
}

.product-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  border: 2px solid var(--clr-gray-bg);
  border-radius: 0.5rem;
  padding: 2rem 1.2rem;
  box-shadow: 0 0.5em 1em -0.5em var(--clr-neutral-black-bs);
  margin-top: 1rem;
}

.product-container[data-type="lower"] {
  margin-top: 2rem;
}

.product-header {
  font-size: var(--fs-650);
}

.product-item {
  display: grid;
  grid-template-rows: 1fr;
  gap: 0.2rem;
}

.product-item-header {
  font-size: var(--fs-600);
  font-weight: var(--fw-bold);
}

.product-item-price {
  font-size: var(--fs-500);
}

/*#endregion Mitgliedschaft */

/*#region Kurspage */

.kurspage-container {
  background-color: var(--clr-neutral-white);
  padding: 3rem 1rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.kurspage-container .horizontal-line {
  border: 1px solid var(--clr-gray-text);
  margin-top: 1rem;
}

.kurspage-header {
  font-size: var(--fs-700);
  font-weight: var(--fw-bold);
}

.kurspage-sub-header {
  font-size: var(--fs-600);
  font-weight: var(--fw-bold);
  margin-top: 1rem;
}

.kurspage-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2.5rem;
}

.kurspage-list-item {
  display: grid;
  grid-template-columns: 1fr;
}

.kurspage-list-item-img {
  width: 100%;
  border-radius: 0.75rem;
}

.kurspage-list-item-header {
  font-size: var(--fs-650);
  margin-top: 0.2rem;
}

.kurspage-list-item-description {
  font-size: var(--fs-400);
  hyphens: auto;
  text-align: justify;
  max-width: 100%;
}

.kurspage-img {
  width: 100%;
  border-radius: 0.75rem;
}

.kurspage-img[data-type="marginUp"] {
  margin-top: 1.5rem;
}

.kurspage-img[data-type="marginDown"] {
  margin-bottom: 1.5rem;
}

.kurspage-paragraph {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.kurspage-paragraph p {
  max-width: 100%;
  text-align: justify;
  hyphens: auto;
  font-size: var(--fs-400);
  line-height: 2;
}

.kurspage-contact {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.kurspage-contact-item {
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  align-items: center;
  padding-left: 0rem;
}

.kurspage-contact-item-img {
  width: 1.6rem;
}

.kurspage-contact-item-content {
  font-size: var(--fs-500);
}

/*#endregion Kurspage */

/*#region Form */

form[data-type="contact"] {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.2rem;
  margin-top: 2rem;
}

form[data-type="contact"] .btn {
  margin-top: 1rem;
}

.form-control {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
}

.form-input {
  height: 2rem;
}

.form-control label {
  font-size: var(--fs-400);
  color: var(--clr-neutral-black);
  font-weight: var(--fw-bold);
}

.form-control input {
  padding: 0 0.3rem;
}

.form-control textarea {
  height: 16rem;
  border-radius: 0.5em;
  padding: 0.5rem;
}

.form-control-info {
  display: flex;
  gap: 1rem;
  padding-left: 0.5rem;
}

.form-control-info input {
  height: 2rem;
  width: 2rem;
}

.form-control-info span {
  font-size: var(--fs-300);
}

.form-control-info span {
  color: black;
}

.form-control-info-hl {
  color: red !important;
}

.form-download {
  display: grid;
  gap: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.form-download .form-control {
  margin-top: 1rem;
}

.form-download .form-control input {
  cursor: pointer;
}

.form-download-header {
  font-weight: var(--fw-bold);
}

.form-download-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  position: relative;
  margin-top: 0.5rem;
}

.form-download-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  height: 2rem;
  margin-bottom: 0.5rem;
  text-decoration: underline;
  color: var(--clr-neutral-black);
}

.form-download-container:last-of-type,
.form-download-container[data-type="placeholder"] {
  margin-bottom: 0rem;
}

.form-download-container:hover {
  opacity: 0.7;
}

.form-download-container p {
  font-size: var(--fs-500);
}

.form-download-container img {
  width: 1.2rem;
  height: 1.2rem;
}

/*#endregion Form */

/*#region Utility classes */

.document-hidden {
  display: none;
  visibility: hidden;
}

.paragraph-wrapper {
  text-align: center;
  display: block;
}

.paragraph-wrapper p {
  text-align: justify;
  hyphens: auto;
}

.paragraph-wrapper a {
  text-decoration: none;
  color: var(--clr-link);
}

.even-areas {
  display: grid;
}

@media (min-width: 54em) {
  .even-areas {
    grid-template-columns: 40% 60%;
  }
}

@media (max-width: 54em) {
  .even-areas {
    grid-template-areas:
      "area-img"
      "area-content";
  }

  .hr-wrapper {
    grid-area: area-content;
  }

  .hr-img-wrapper {
    grid-area: area-img;
  }
}

.even-areas[data-type="gap"] {
  gap: var(--size-500);
}

.even-columns {
  display: grid;
  gap: 1rem;
}

@media (min-width: 50em) {
  .even-columns {
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }
}

.text-center {
  text-align: center;
}

.text-center p {
  margin-inline: auto;
}

.fw-bold {
  font-weight: var(--fw-bold);
}
.fw-semi-bold {
  font-weight: var(--fw-semi-bold);
}
.fw-regular {
  font-weight: var(--fw-regular);
}

.fs-primary-heading {
  font-size: var(--fs-700);
  font-weight: var(--fw-bold);
  line-height: 1.1;
  margin-bottom: var(--size-300);
}

.padding-block-900 {
  padding: var(--size-900) 0;
}

.padding-block-700 {
  padding: var(--size-700) 0;
}

.padding-block-500 {
  padding: var(--size-500) 0;
}

.margin-block-500 {
  margin: var(--size-500) 0;
}

.padding-inline {
  padding-right: 1.2rem;
  padding-left: 1.2rem;
}

.margin-inline {
  margin-right: auto;
  margin-left: auto;
}

.horizontal-line {
  border-bottom: 2px solid var(--clr-shadow-text);
  width: 100%;
}

.bwt-bg-wrapper {
  background-color: var(--clr-neutral-black);
}

.bwt-fg-wrapper {
  background-color: hsla(0, 0%, 100%, 0.1);
}

.misc-heading {
  font-size: var(--fs-650);
  font-weight: var(--fw-bold);
  color: var(--clr-gray-accent-100);
}

.misc-paragraph {
  font-size: var(--fs-400);
  hyphens: auto;
  line-height: 2;
}

.misc-paragraph p {
  max-width: 100%;
  margin: auto;
}

.misc-paragraph h2 {
  font-size: var(--fs-625);
}

.misc-paragraph h3 {
  font-size: var(--fs-500);
}

.misc-paragraph li {
  margin-left: 3rem;
}

.container {
  padding: 0 1rem;
  background-color: var(--clr-neutral-white);
}

/*#endregion*/

/*#region Media Queries */

/* Large Desktops */
@media (min-width: 114em) {
}

/* Large Laptops */
@media (min-width: 66em) and (max-width: 114em) {
  /*#region Forms */

  form[data-type="contact"] {
    gap: 2rem;
    width: 50%;
    margin: auto;
    margin-top: 3rem !important;
    margin-bottom: 3rem;
  }

  form button {
    margin: auto;
    margin-top: 1rem;
    width: 20rem;
  }

  .form-control {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }

  .form-control textarea {
    height: 15rem;
  }

  .form-control-info input {
    margin-right: 0.4rem;
    height: 1.2rem;
    width: 1.2rem;
  }

  .form-control-info span {
    font-size: var(--fs-500);
  }

  /*#endregion Forms */
}

@media (min-width: 30em) {
  /*#region Nav */

  .upper-nav {
    padding: 0.5rem 0;
  }

  .upper-nav p {
    font-size: var(--fs-500);
  }

  .primary-header {
    padding: 0;
  }

  .nav-logo {
    max-width: 14rem;
  }

  .nav {
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 76%;
    margin: auto;
    grid-template-columns: 20% 80%;
  }

  .nav-links {
    width: 100%;
  }

  .nav-extended-links {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .nav-extended-links .btn {
    padding: 1rem 1.5rem;
    margin: auto;
    margin-left: 3rem;
  }

  .nav-link {
    padding: 1rem 1.5rem;
    max-height: 100%;
  }

  .nav-link-dropdown {
    position: relative;
  }

  .nav-link-dropdown-btn {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    justify-items: flex-start;
    gap: 1rem;
  }

  .nav-link-dropdown-btn-text[data-type="active"],
  .nav-link-dropdown-btn-text:hover,
  .nav-modal-link:hover,
  .nav-link:hover {
    color: var(--clr-accent-700);
  }

  .nav-link-dropdown-btn img {
    height: 0.4rem;
  }

  .nav-modal {
    background-color: var(--clr-neutral-white);
    position: absolute;
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.8rem;
    z-index: 100;
    padding: 2rem 1.5rem;
    width: 18rem;
    top: 0;
    margin-top: 3rem;
    left: 0rem;
    border-radius: 0 0 1rem 1rem;
    box-shadow: 0 0.75em 1.56em -0.4375em var(--clr-neutral-black-transparent);
    border: 1px solid var(--clr-neutral-black-transparent);
  }

  .nav-modal[data-type="inactive"] {
    display: none;
  }

  .nav-modal-link {
    text-decoration: none;
    color: var(--clr-neutral-black);
    font-weight: var(--fw-regular);
  }

  /*#endregion Nav */

  /* #region Home */

  #home .card-slider {
    height: 90vh;
    overflow: hidden;
  }

  #home .card {
    width: 100%;
    margin-bottom: 8rem;
  }

  #home .card img {
    width: 100%;
    transform: scale(1);
  }

  #home .card-slider-pagination-wrapper {
    margin-right: 3vh;
    margin-bottom: 3vh;
  }

  #home .card-slider-pagination-item {
    height: 1.5rem;
    width: 1.5rem;
  }

  /* #endregion Home */

  /* #region Welcome */

  .welcome-wrapper {
    max-width: 60%;
  }

  .welcome-header {
    max-width: 100%;
    font-size: var(--fs-625);
  }

  .welcome-paragraph {
    max-width: 64ch;
    text-align: center;
    margin: auto;
  }

  .welcome-paragraph[data-type="team-list"] {
    font-weight: var(--fw-bold);
    font-size: var(--fs-600);
    margin-top: 1.5rem;
  }

  .welcome-team-list {
    display: flex;
    flex-direction: row;
    margin: auto;
    margin-top: 1rem;
    width: 90%;
    align-items: center;
    justify-content: center;
  }

  .welcome-team-list li {
    margin: auto;
    list-style-type: none;
    text-align: center;
  }

  /* #endregion Welcome */

  /* #region Aktuelles */

  .news-wrapper {
    margin: 3rem auto 6rem auto !important;
    width: 60%;
  }

  .news-list-item-date {
    font-size: var(--fs-500);
  }

  .news-list-item-text {
    max-width: 100%;
  }

  .news-list-item {
    display: grid;
    background-color: var(--clr-neutral-white);
    margin: auto;
    grid-template-columns: 40% 60%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 0.3rem;
  }

  .news-list-item-img {
    border-radius: 0.3rem 0 0 0.3rem;
  }

  .news-list-item-content {
    padding: 1rem 3rem;
    gap: 0.4rem;
  }

  .news-list-item-header {
    font-size: var(--fs-650);
  }

  .news-list-item-date {
    max-width: 100%;
    font-size: var(--fs-400);
  }

  .news-list-item-text {
    max-width: 100%;
  }

  .news-list-item-list {
    padding-left: 2rem;
  }

  .news-list-item-list-item,
  .news-list-item-text {
    font-size: var(--fs-300);
  }

  .news-list-item-link {
    font-size: var(--fs-400);
  }

  /* #endregion Aktuelles */

  /* #region Leistungen */

  .service-wrapper {
    padding: 3rem 0 3rem 3rem;
  }

  .service-heading {
    font-size: var(--fs-700);
  }

  .service-even-areas {
    display: grid;
    grid-template-columns: 1.75fr 1.25fr;
    overflow: hidden;
  }

  .service-img {
    height: 100%;
  }

  .service-item {
    grid-template-columns: 1fr 9fr;
  }

  .service-item-preview {
    max-width: 50ch;
    hyphens: auto;
  }

  .service-item-nav:hover {
    opacity: 0.7;
  }

  .service-wrapper .btn {
    margin-left: 3rem !important;
    margin-right: auto;
  }

  /* #endregion Leistungen */

  /* #region Location */

  #location {
    padding-top: 3rem;
    padding-bottom: 2rem;
  }

  #location iframe {
    height: 58vh;
  }

  /* #endregion Location */

  /* #region Kurspage */

  .card-slider-controller {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 97;
    display: grid;
    grid-template-columns: 8% 84% 8%;
    align-items: center;
    user-select: none;
  }

  .card-slider-controller-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .card-slider-controller-chevron {
    padding: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    border-radius: 10rem;
    margin: auto;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.181);
  }

  .card-slider-controller-item img {
    width: 3rem;
  }

  .kurspage-container,
  .subscription-container {
    width: 76%;
    margin: auto;
    padding: 12vh 0 5rem 0;
  }

  .kurspage-header,
  .subscription-header {
    text-align: center;
  }

  .kurspage-container .card-slider {
    height: 100%;
    margin-top: 0.5rem;
  }

  .kurspage-container .card {
    overflow: visible;
  }

  .kurspage-container .card img {
    width: 74rem;
    margin: auto;
    transform: scale(1);
    border-radius: 1rem;
    padding-top: 0;
  }

  .kurspage-container .kurspage-img {
    width: 50rem;
    margin: auto;
    transform: scale(1);
    border-radius: 1rem;
    padding-top: 0;
  }

  .kurspage-paragraph-wrapper {
    width: 60%;
    margin: auto;
  }

  .kurspage-paragraph {
    max-width: 50rem;
    hyphens: auto;
    line-height: 2;
    margin: auto;
    text-align: justify;
  }

  .kurspage-paragraph[data-type="mt"] {
    margin-top: 1rem;
  }

  .kurspage-sub-header[data-type="contact"] {
    width: 50rem;
    margin: auto;
    margin-top: 2rem;
  }

  .kurspage-contact {
    width: 50rem;
    margin: auto;
    margin-bottom: 2rem;
  }

  .kurspage-contact-btn {
    display: flex;
    justify-content: flex-start;
    width: 50rem;
    margin: auto;
  }

  .kurspage-container .btn {
    width: 20rem;
    margin: auto;
  }

  .subscription-list,
  .product-container {
    width: 50rem;
    margin: auto;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  .subscription-info {
    width: 50rem;
    margin: auto;
    margin-top: 2rem;
  }

  .kurs-top-img {
    width: 100%;
    height: 88vh;
    overflow: hidden;
  }

  .kurs-top-img img {
    margin-top: 3rem;
    transform: scale(1);
  }

  .kurs-header {
    width: 80%;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 1rem;
    gap: 0.5rem;
  }

  .kurs-header-heading {
    font-size: var(--fs-700);
  }

  .kurs-header-sub-heading {
    line-height: 2;
    hyphens: auto;
    max-width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }

  .kurs-header .btn {
    width: 20rem;
    margin: auto;
  }

  .horizontal-line {
    width: 80%;
    margin: auto;
  }

  .kurs-group-container {
    width: 80%;
    margin: auto;
    margin-bottom: 2rem;
  }

  .kurs-group-header {
    font-size: var(--fs-700);
  }

  .kurs-group {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .kurs-reminder-container {
    width: 76%;
    margin: auto;
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 0;
    height: 25rem;
    gap: 0;
    overflow: hidden;
    height: 100%;
  }

  .kurs-reminder-img {
    width: 100%;
  }

  .kurs-reminder-content {
    padding: 5rem 0 5rem 4rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 40% 20% 40%;
    width: 100%;
  }

  .kurs-reminder-header {
    font-size: var(--fs-700);
  }

  .kurs-reminder-content .even-areas {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .kurs-reminder-content .even-areas .btn {
    align-items: center;
    height: 3.5rem;
  }

  .kurspage-text-img {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    width: 60%;
    margin: auto;
  }

  .kurspage-sub-header {
    width: 50rem;
    margin: auto;
  }

  .horizontal-line {
    width: 54%;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .kurspage-list {
    width: 60%;
    margin: auto;
    grid-template-columns: 1fr 1fr;
    margin-top: 2rem;
  }

  .kurspage-list-item {
    grid-template-rows: 18rem 3rem 1fr;
    gap: 0.2rem;
  }

  .kurspage-list-item img {
    width: 100%;
    height: 100%;
  }

  /* #endregion Kurspage */

  /* #region Kursplan */

  .kurs-plan-container {
    margin-top: 2rem;
    width: 70%;
    margin: auto;
  }

  .kurs-plan-week-control {
    width: 50%;
    margin: auto;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  /* #endregion Kursplan */

  /* #region Footer */

  .primary-footer-wrapper {
    width: 76%;
    margin: auto;
    padding: 3rem 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .primary-footer-officeTimes {
    margin-top: 0;
    width: 50%;
  }

  .primary-footer-nav {
    grid-template-columns: 1fr;
    justify-content: center !important;
    width: auto;
  }

  .footer-nav-group {
    justify-content: center;
    margin: auto;
    width: 100%;
  }

  .footer-nav-group a {
    text-align: left;
  }

  .primary-footer-links-wrapper {
    display: grid;
    grid-template-rows: 2fr 1fr;
    gap: 4rem;
    width: 100%;
    justify-content: center;
  }

  .primary-footer-links {
    grid-template-columns: 1fr;
    justify-content: center !important;
    width: auto;
  }

  /* #endregion Footer */

  /*#region Forms */

  .form-submit-message {
    display: grid;
    gap: 1rem;
    line-height: 2;
    width: 50%;
    margin: auto;
    margin-top: 3rem;
    margin-bottom: 7rem;
  }

  .form-submit-message p {
    max-width: 100%;
    text-align: justify;
  }

  .form-submit-message .btn {
    margin: 3rem auto 0 auto;
    width: 30rem;
  }

  form[data-type="subscription-form"] {
    gap: 2rem;
    width: 50rem;
    margin-top: 3rem !important;
    margin: auto;
  }

  form[data-type="contact"] {
    gap: 2rem;
    width: 50%;
    margin: auto;
    margin-top: 1rem !important;
  }

  form button {
    margin-top: 2rem;
  }

  .form-control {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }

  .form-control textarea {
    height: 15rem;
  }

  .form-control-info {
    gap: 0.2rem;
    align-items: center;
  }

  .form-control-info input {
    margin-right: 1rem;
    width: 1rem;
  }

  .form-control-info span {
    max-width: 100%;
  }

  .form-download {
    padding-top: 0.2rem;
    padding-bottom: 0.5rem;
  }

  .form-download .form-control:last-of-type {
    margin-top: 2rem;
  }

  .form-download .form-control:first-of-type {
    margin-top: 0rem;
  }

  .form-download-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }

  .form-download-container:last-of-type,
  .form-download-container[data-type="placeholder"] {
    margin-bottom: 0rem;
  }

  .form-download-container:hover {
    opacity: 0.7;
  }

  .form-download-container p {
    font-size: var(--fs-500);
  }

  .form-download-container img {
    width: 1.2rem;
    height: 1.2rem;
  }

  /*#endregion Forms */

  /* #region Utility classes */

  .container {
    padding: 0 8%;
  }

  .container p {
    max-width: 100%;
  }

  .padding-inline {
    width: 76%;
    margin: auto;
  }

  .fs-primary-heading {
    margin-bottom: 2rem;
  }

  /* #endregion Utility classes */
}

@media (min-width: 30em) and (max-width: 114em) {
  .nav-placeholder {
    width: 2%;
  }

  @media (max-width: 87.5em) {
    .nav-link span,
    .nav-link-dropdown-btn-text {
      font-size: var(--fs-600);
    }
  }

  .nav-logo {
    max-width: 12rem;
  }

  .nav-link {
    font-size: var(--fs-500);
  }

  .kurs-group-container {
    width: 90%;
  }

  .kurs-group-item-link {
    font-size: var(--fs-300);
  }

  .kurs-group-item-header {
    font-size: var(--fs-500);
  }
}

/*#endregion Media Queries */
